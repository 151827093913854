// features/loginModalSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface LoginModalState {
  showModal: boolean;
  showSignUp: boolean;
  showForgot: boolean;
  showNewPassword: boolean;
  showOtp: boolean;
  showLogin: boolean;
  showMobileLogin: boolean;
  signupData: any;
  loginOtpCheck: boolean;
  forgotOtpCheck: boolean;
  otpMobile: string;
  forgotInput: string;
  newPasswordData: {
    [key: string]: any;
  };
  showForgotMessage: boolean;
}

const initialState: LoginModalState = {
  showModal: false,
  showSignUp: false,
  showForgot: false,
  showNewPassword: false,
  showOtp: false,
  showLogin: true,
  showMobileLogin: false,
  signupData: {},
  loginOtpCheck: false,
  forgotOtpCheck: false,
  otpMobile: '',
  forgotInput: '',
  newPasswordData: {},
  showForgotMessage: false,
};

const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    showLoginModal: (state) => {
      state.showLogin = true;
      state.showModal = true;
    },
    hideLoginModal: (state) => {
      state.showModal = false;
    },
    showSignUp: (state) => {
      state.showSignUp = true;
    },
    hideSignUp: (state) => {
      state.showSignUp = false;
    },
    showForgot: (state) => {
      state.showForgot = true;
    },
    hideForgot: (state) => {
      state.showForgot = false;
    },
    showNewPassword: (state) => {
      state.showNewPassword = true;
    },
    hideNewPassword: (state) => {
      state.showNewPassword = false;
    },
    showOtp: (state) => {
      state.showOtp = true;
    },
    hideOtp: (state) => {
      state.showOtp = false;
    },
    showLogin: (state) => {
      state.showLogin = true;
    },
    hideLogin: (state) => {
      state.showLogin = false;
    },
    setSignUpData: (state, action) => {
      state.signupData = action.payload;
    },
    showMobileLoginModal: (state) => {
      state.showMobileLogin = true;
    },
    hideMobileLoginModal: (state) => {
      state.showMobileLogin = false;
    },
    setLoginOtpCheck: (state, action) => {
      state.loginOtpCheck = action.payload;
    },
    setForgotOtpCheck: (state, action) => {
      state.forgotOtpCheck = action.payload;
    },
    setOtpMobileData: (state, action) => {
      state.otpMobile = action.payload;
    },
    setForgotData: (state, action) => {
      state.forgotInput = action.payload;
    },
    setShowForgotSuccessMessage: (state, action) => {
      state.showForgotMessage = action.payload;
    },
    setNewPasswordData: (state, action) => {
      state.newPasswordData = action.payload;
    },
    resetLoginModal: (state) => {
      state.showModal = false;
      state.showSignUp = false;
      state.showForgot = false;
      state.showNewPassword = false;
      state.showOtp = false;
      state.showLogin = false;
      state.signupData = {};
      state.showMobileLogin = false;
      state.loginOtpCheck = false;
      state.forgotOtpCheck = false;
      state.otpMobile = '';
      state.forgotInput = '';
      state.newPasswordData = {};
      state.showForgotMessage = false;
    },
  },
});

export const {
  showLoginModal,
  hideLoginModal,
  showSignUp,
  hideSignUp,
  showForgot,
  hideForgot,
  showNewPassword,
  hideNewPassword,
  showOtp,
  hideOtp,
  showLogin,
  hideLogin,
  setSignUpData,
  showMobileLoginModal,
  hideMobileLoginModal,
  resetLoginModal,
  setLoginOtpCheck,
  setOtpMobileData,
  setForgotData,
  setForgotOtpCheck,
  setNewPasswordData,
  setShowForgotSuccessMessage,
} = loginModalSlice.actions;

export default loginModalSlice.reducer;
