import { apiService } from '@/services/ApiService';
import { setCookie, deleteCookie, getCookie } from 'cookies-next';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { calculateDistance } from '@/utils/common';

interface StoreLocationState {
  location: any;
  storesList: any;
  pinstr: string;
}

const initialState: StoreLocationState = {
  location: {},
  storesList: [],
  pinstr: getCookie('pincode') ?? '',
};

export const getCityStoresList = createAsyncThunk(
  'stores/getCityStoresList',
  async () => {
    const response = await apiService.get(`getCityStores`);
    return response;
  }
);

export const getStoreLocation = createAsyncThunk(
  'getStoreLocation',
  async (data: any, { dispatch }) => {
    if (data?.pin && data?.pin?.trim() === '') return;
    if (!data?.isCity) {
      deleteCookie('city');
      deleteCookie('state');
      deleteCookie('pincode');
      localStorage.removeItem('location');
    }
    const response: any = await apiService.get(
      `getStoreLocation?location=${data?.pin}`,
      '',
      '',
      false
    );
    const rows = response?.data?.rows;
    if (response?.code == 200 && rows?.length > 0) {
      rows.forEach((address: any) => {
        if (address?.store_address?.length > 0) {
          const show = address?.store_address?.split(',');
          const len = show?.length;
          let display = show[len - 4] ? show[len - 4]?.trim() + ',' : '';
          display += show[len - 3] ? show[len - 3]?.trim() + ',' : '';
          display += show[len - 2] ? show[len - 2]?.trim() + ',' : '';
          display += show[len - 1] ? show[len - 1]?.trim() + ',' : '';
          address.display = display;
        }
      });
      if (!data?.isCity) {
        const pincode = rows[0]?.pincode;
        const city = rows[0]?.city;
        const state = rows[0]?.state;
        const pcity = city ? city[0]?.toUpperCase() + city?.substring(1) : '';
        city && setCookie('city', pcity);
        state && setCookie('state', state);
        Number(pincode) && setCookie('pincode', pincode);
        if (rows[0]?.store_address) {
          //   rows?.forEach((arg: any) => {
          //     if ((arg?.latitude?.length > 0 || arg?.lat?.length > 0) &&
          //       (arg.longitude?.length > 0 || arg.long?.length > 0) &&
          //       arg?.wlat?.length > 0 && arg.wlong?.length > 0) {
          //       arg.dist = calculateDistance(//         arg.latitude ?? arg?.lat,arg.longitude ?? arg.long,arg.wlat,arg.wlong);} else arg.dist = 100;});
          // const drow = rows.sort((a: any, b: any) => a.dist - b.dist);
          const drow = rows?.sort(
            (a: any, b: any) => b.information_id - a.information_id
          );
          localStorage.setItem('location', JSON.stringify(drow));
        }
        dispatch(setStoreLocationDetails(response.data));
        dispatch(setStorePincode(data?.pin));
      } // else Number(data?.pin) && setCookie('pincode', data?.pin);
      return rows;
    } else if (!data?.isCity) {
      dispatch(setStoreLocationDetails([]));
      Number(data?.pin) && setCookie('pincode', data?.pin);
    }
    return false;
  }
);

const storeLocationSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setStoreLocationDetails: (state, action) => {
      state.location = action.payload;
      // state.pinstr = action.payload[0]?.pincode ?? '';
    },
    setStorePincode: (state, action) => {
      state.pinstr = action.payload ?? '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCityStoresList.fulfilled, (state, action: any) => {
      state.storesList = action.payload?.data.cities;
    });
  },
});

export const { setStoreLocationDetails, setStorePincode } =
  storeLocationSlice.actions;
export const getStoreLocationDetails = ({ stores }: { stores: any }) =>
  stores?.location;
export const getAllCityStoresList = ({ stores }: { stores: any }) => {
  return stores?.storesList;
};
export const getStoresPincode = ({ stores }: { stores: any }) => {
  return stores?.pinstr;
};

export default storeLocationSlice.reducer;
