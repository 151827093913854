import { getCookie } from 'cookies-next';
import { apiService } from '@/services/ApiService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import store from '..';

// Define the shape of the cart state
interface CRMState {
  isMobile: boolean;
}

// Define the initial state
const initialState: CRMState = {
  isMobile: false,
};
export const addZendeskticket = createAsyncThunk(
  'createTicket',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const token = !getCookie('woodenstreetUserAuthToken');
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const res = await apiService.post(`zendesk/createTicket`, post, !token);
    return res;
  }
);
export const addRequestcallback = createAsyncThunk(
  'addRequestcallback',
  async (params: any) => {
    const token = !getCookie('woodenstreetUserAuthToken');
    if (token) params.token = token;
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/addrequestcallback`, post);
    return response;
  }
);
export const addPriseDropLeads = createAsyncThunk(
  'addPriseDropLeads',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/addPriseDropLeads`, post);
    return response;
  }
);
export const addProductPinLeads = createAsyncThunk(
  'addProductPinLeads',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/addProductPinLeads`, post);
    return response;
  }
);
export const addSignupLeads = createAsyncThunk(
  'addSignupLeads',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/addSignupLeads`, post);
    return response;
  }
);
export const addPincodeLead = createAsyncThunk(
  'addPincodeLead',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/addPincodeLead`, post);
    return response;
  }
);
export const supportForm = createAsyncThunk(
  'supportForm',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/supportForm`, post);
    return response;
  }
);
export const supportFormUnResolved = createAsyncThunk(
  'supportFormUnResolved',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/supportFormUnResolved`, post);
    return response;
  }
);
export const crmOrderConfirm = createAsyncThunk(
  'crmOrderConfirm',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/orderConfirm`, post);
    return response;
  }
);
export const outOfStockLeads = createAsyncThunk(
  'outOfStockLeads',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/outOfStock`, post);
    return response;
  }
);
export const furnitureExporters = createAsyncThunk(
  'furnitureExporters',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/furnitureExporters`, post);
    return response;
  }
);
export const sellOnWoodenstreet = createAsyncThunk(
  'sellOnWoodenstreet',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/sellOnWoodenstreet`, post);
    return response;
  }
);
export const addCartLead = createAsyncThunk(
  'addCartLead',
  async (params: any) => {
    const isMobile = store.getState()?.crm?.isMobile;
    const post = { ...params, deviceType: isMobile ? 'mobile' : 'desktop' };
    const response = await apiService.post(`crm/addCartLead`, post);
    return response;
  }
);
const crmSlice = createSlice({
  name: 'crm',
  initialState,
  reducers: {
    setMobileScreen: (state, action) => {
      state.isMobile = action.payload;
    },
  },
  extraReducers: () => {},
});
export const { setMobileScreen } = crmSlice.actions;
export const getMobileScreen = ({ common }: { common: any }) =>
  common?.isMobile;
export default crmSlice.reducer;
