import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosHeaders,
  AxiosResponse,
} from 'axios';
import { userService } from './userService';
import Router from 'next/router';
import { getCookie } from 'cookies-next';

const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || '';
const WS_S3_BUCKET_BASE_URL =
  process.env.NEXT_PUBLIC_WS_S3_BUCKET_BASE_URL || '';
const ZENDESK_URL = process.env.NEXT_PUBLIC_API_ZENDESK_URL || '';

export interface ApiResponse<T> {
  code: number;
  message: string;
  data: T;
}

export interface ApiService {
  get<T>(
    url: string,
    id?: any,
    params?: any,
    useAuth?: boolean
  ): Promise<ApiResponse<T>>;
  post<T>(url: string, data: any, useAuth?: boolean): Promise<ApiResponse<T>>;

  put<T>(
    url: string,
    id: any,
    data: any,
    useAuth?: boolean
  ): Promise<ApiResponse<T>>;
  patch<T>(
    url: string,
    id: any,
    data: any,
    useAuth?: boolean
  ): Promise<ApiResponse<T>>;
  delete<T>(url: string, id: any, useAuth?: boolean): Promise<ApiResponse<T>>;
  zenpost<T>(
    url: string,
    data: any,
    useAuth?: boolean
  ): Promise<ApiResponse<T>>;
}

class AxiosApiService implements ApiService {
  private axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
    });

    this.axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const headers = config.headers as AxiosHeaders;
        if (headers.get('Use-Auth') === 'true') {
          const authHeader = this.getAuthHeader();
          headers.set('Authorization', authHeader.Authorization);
        } else if (headers.get('Zen-Auth') === 'true') {
          const authHeader = this.getZenAuthHeader();
          headers.set('Authorization', authHeader.Authorization);
          headers.set('Content-Type', 'application/json');
          headers.set('Access-Control-Allow-Origin', '*');
        }
        headers.delete('Use-Auth');
        headers.delete('Zen-Auth');
        return config;
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      this.handleErrorResponse
    );
  }

  private getAuthHeader(): { Authorization: string } {
    const token = getCookie('woodenstreetUserAuthToken');
    return { Authorization: token ? `Bearer ${token}` : '' };
  }
  private getZenAuthHeader(): { Authorization: string } {
    const zendeskPass = process.env.NEXT_PUBLIC_API_ZENDESK_PASSWORD;
    return { Authorization: zendeskPass ? `Basic ${zendeskPass}` : '' };
  }

  async get<T>(
    url: string,
    id?: any,
    params?: any,
    useAuth: boolean = false
  ): Promise<ApiResponse<T>> {
    const requestUrl = id ? `${url}/${id}` : url;
    const response = await this.axiosInstance.get<ApiResponse<T>>(requestUrl, {
      params,
      headers: { 'Use-Auth': useAuth.toString() },
    });
    return this.handleSuccessResponse(response);
  }

  async post<T>(
    url: string,
    data: any,
    useAuth: boolean = false
  ): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.post<ApiResponse<T>>(url, data, {
      headers: { 'Use-Auth': useAuth.toString() },
      // headers:{'Authorization', 'Basic ' + zendeskPass}
    });
    return this.handleSuccessResponse(response);
  }

  async put<T>(
    url: string,
    id: any,
    data: any,
    useAuth: boolean = false
  ): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.put<ApiResponse<T>>(
      `${url}${id && '/' + id}`,
      data,
      {
        headers: { 'Use-Auth': useAuth.toString() },
      }
    );
    return this.handleSuccessResponse(response);
  }

  async patch<T>(
    url: string,
    id: any,
    data: any,
    useAuth: boolean = false
  ): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.patch<ApiResponse<T>>(
      `${url}${id && '/' + id}`,
      data,
      {
        headers: { 'Use-Auth': useAuth.toString() },
      }
    );
    return this.handleSuccessResponse(response);
  }

  async delete<T>(
    url: string,
    id: any,
    useAuth: boolean = false
  ): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.delete<ApiResponse<T>>(
      `${url}/${id}`,
      {
        headers: { 'Use-Auth': useAuth.toString() },
      }
    );
    return this.handleSuccessResponse(response);
  }

  async zenpost<T>(url: string, data: any): Promise<ApiResponse<T>> {
    const response = await this.axiosInstance.post<ApiResponse<T>>(url, data, {
      headers: { 'Zen-Auth': 'true' },
      // headers:{'Authorization', 'Basic ' + zendeskPass}
    });
    return this.handleSuccessResponse(response);
  }

  private handleSuccessResponse<T>(
    response: AxiosResponse<ApiResponse<T>>
  ): ApiResponse<T> {
    if (response.config.baseURL == WS_S3_BUCKET_BASE_URL) {
      return response.data;
    }
    const { code, message, data } = response.data;

    // You can add any global success handling here if needed
    // For example, you might want to show a success toast for certain status codes
    // if (code === 200 || code === 201) {
    //   toast.success(message, { toastId: message });
    // }

    return { code, message, data };
  }

  private handleErrorResponse(error: any): Promise<never> {
    if (axios.isAxiosError(error) && error.response) {
      const { code, message, data } = error.response.data as ApiResponse<any>;
      if ([401, 403].includes(code)) {
        if (code === 403) {
          Router.push('/');
        } else {
          userService.logout();
        }
      }

      // toast.error(message, { toastId: message });
      return Promise.reject({ code, message, data });
    }

    // If it's not an AxiosError or doesn't have a response, return a generic error
    return Promise.reject({
      code: 500,
      message: 'An unexpected error occurred',
      data: error,
    });
  }
}

export const apiService: ApiService = new AxiosApiService(BASE_URL);
export const apiServiceForWSS3Bucket: ApiService = new AxiosApiService(
  WS_S3_BUCKET_BASE_URL
);
export const apiServiceForZendesk: ApiService = new AxiosApiService(
  ZENDESK_URL
);
